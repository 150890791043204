import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import './App.scss';
import PrivateRoute from '../Router/PrivateRoute';
import Header from '../Header/Header';
import Stepper from '../Stepper/Stepper';
import Reports from '../Reports/Reports';
import ReportDetails from '../Reports/ReportDetails';
import Projects from '../Projects/Projects';
import Users from '../Users/Users';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../redux/actions';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from '../../config/firebase'
import { uiConfig } from '../../config'
// import jwt from 'jwt-decode';

export default () => {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useSelector(state => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(user => {
        if(user) {
          // user.getIdToken().then(jwtToken => {
          //   const token = jwt(jwtToken);
          // });

          // TODO: refactor this
          firebase
          .firestore()
          .collection("users")
          .where("email", "==", user.email)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              dispatch(setUser({
                id: doc.id,
                ...doc.data(),
              }));
            });
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      });

    return () => unsubscribe();  
  }, [dispatch]);

  if(loading) {
    return (
      <div className="circularProgress">
        <CircularProgress />
      </div>
    )
  }
  return (
    <div className="container">
      <Router>
        <Header />
        {currentUser.isAuth ? (
          <>
            <Route exact path="/" component={Stepper} />
            <PrivateRoute exact path="/reports" component={Reports} />
            <PrivateRoute path="/reports/:id" component={ReportDetails} />
            <PrivateRoute exact path="/projects" component={Projects} />
            <PrivateRoute exact path="/users" component={Users} />
          </>
        ): (
          <StyledFirebaseAuth 
            uiConfig={uiConfig as any}
            firebaseAuth={firebase.auth()}
          />
        )}
      </Router>
    </div>
  );
}
