import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Avatar, Typography } from '@material-ui/core';
import ModalEditForm from './ModalEditForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    avatarLarge: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    content: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      outline: 'none',
      maxHeight: '80vh',
      maxWidth: 190,
      overflow: 'auto',
    },
  }),
);

interface IModalProps {
  open: boolean;
  onClose: any;
  user: any;
}

const ModalEdit = ({ open, onClose, user }: IModalProps) => {
  const classes = useStyles();

  if(!open) return null;
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.content}>
        <div className={classes.header}>
          <Avatar
            className={classes.avatarLarge}
            src={process.env.PUBLIC_URL + `/images/${user.username}.jpg`}
            alt="user"
          />
          <Typography variant="h5">{user.username}</Typography>
          <Typography variant="caption">{user.email}</Typography>
        </div>
        <ModalEditForm user={user} onClose={onClose} />
      </div>
    </Modal>
  );
}

export default ModalEdit;
