import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal, Backdrop } from '@material-ui/core';
import ModalAddForm from './ModalAddForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    avatarLarge: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    content: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      outline: 'none',
      maxHeight: '80vh',
      maxWidth: 190,
      overflow: 'auto',
    },
  }),
);

interface IModalProps {
  open: boolean;
  onClose: any;
}

const ModalAdd = ({ open, onClose }: IModalProps) => {
  const classes = useStyles();

  if(!open) return null;
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.content}>
        <ModalAddForm onClose={onClose} />
      </div>
    </Modal>
  );
}

export default ModalAdd;
