import { combineReducers } from 'redux';
import users from './users';
import questions from './questions';

let reducers = {
  users,
  questions,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
