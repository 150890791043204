export const QUESTIONS = [
  {
    label: 'Q1: Ugrožen je dogovoreni rok isporuke?',
    helperText: "Smatraš kako projekt ili tekuća faza neće biti isporučeni u roku u kojem je dogovoreno.",
    detailsText: "Zbog čega je ugrožen dogovoreni rok isporuke?",
  },
  {
    label: 'Q2: Postoji problem sa stanjem/kvalitetom isporuke?',
    helperText: 'Smatraš kako projekt, modul, sprint ili tekuća faza neće biti isporučeni u obimu u kojem je dogovoreno.',
    detailsText: 'Koji je problem sa stanjem/kvalitetom isporuke?',
  },
  {
    label: 'Q3: Postoji problem s utilizacijom resursa?',
    helperText: 'Imaš resurse na projektu koji ne ispunjaju planiranu kvotu radnih sati ili isporuka, imaš ljude koji čekaju, ili su neplanirano odsutni.',
    detailsText: 'Koji je problem sa utilizacijom resursa?',
  },
  {
    label: 'Q4: Postoji problem sa izdavanjem računa u smislu planiranog vremena ili iznosa?',
    helperText: 'Imaš bilo kakvu spoznaju koja može utjecati na to da nećemo izdati račun u vremenu i iznosu kako je planirano.',
    detailsText: 'Koji je problem sa izdavanjem računa?',
  },
];
