import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Button, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import firebase from '../../config/firebase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      outline: 'none',
      maxHeight: '80vh',
      overflow: 'auto',
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginLeft: theme.spacing(1.5),
    },
  }),
);

interface IModalProps {
  open: boolean;
  onClose: any;
  user: any;
}

const ModalDelete = ({ open, onClose, user }: IModalProps) => {
  const classes = useStyles();

  const handleDelete = () => {
    firebase.firestore().collection('users').doc(user.id).delete();
    onClose();
  };

  if(!open) return null;
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.content}>
        <Typography className={classes.title} variant="h5">
          Delete {user.username}?
        </Typography>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="primary"
          startIcon={<DeleteOutline />}
        >
          Delete
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          className={classes.button}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDelete;
