import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Card, CardHeader, CardContent, CardActions, Collapse, Avatar, IconButton, Typography, Divider, CircularProgress } from '@material-ui/core';
import { ExpandMore, Close } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { QUESTIONS } from '../../config';
import firebase from '../../config/firebase';
import { unixToDate } from '../../utils/datetime';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
      margin: '0 auto',
      marginTop: theme.spacing(1),
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    questionTitle: {
      fontWeight: 700,
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    dividerPrimary: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    circularProgress: {
      textAlign: 'center',
      marginTop: '25%',
    },
  }),
);

const ReportDetails = ({ match }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(true);
  const [report, setReport] = useState<any>({});

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('reports')
      .doc(match.params.id)
      .onSnapshot(doc => {
        setReport({
          id: doc.id,
          ...doc.data(),
        });
        setLoading(false);
      });

    return () => unsubscribe();  
  }, [match.params.id]);

  const firstQuestion = report && report.questions && report.questions[0];
  const lastQuestion = report && report.questions && report.questions[report.questions.length - 1];
  const allQuestions = report && report.questions && report.questions.slice(1, report.questions.length - 1);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if(loading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <Card className={classes.root} raised>
      <CardHeader
        avatar={
          <Avatar 
            alt="user"
            src={process.env.PUBLIC_URL + `/images/${report && report.currentUser && report.currentUser.username}.jpg`}
          />
        }
        action={
          <NavLink to="/reports">
            <IconButton aria-label="close">
              <Close />
            </IconButton>
          </NavLink>
        }
        title={`${report && report.currentUser && report.currentUser.username}'s report`}
        subheader={unixToDate(report.date)}
      />
      <CardContent>
        <Typography className={classes.questionTitle}>Postoje li problemi ili izazovi?</Typography>
        <Typography variant="caption">{firstQuestion && firstQuestion.value ? 'Da' : 'Ne'}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMore />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {allQuestions && allQuestions.map((question, index) =>
            <div key={index}>
              {question.value ? (
                <>
                  <Typography className={classes.questionTitle}>{QUESTIONS[index].label}</Typography>
                  {question.answers.map((answer, i) =>
                    <div key={i}>
                      <Typography variant="caption" display="block"><strong>Projekt:</strong> {`${answer.project}`}</Typography>
                      <Typography variant="caption" display="block"><strong>Detalji:</strong> {`${answer.notes}`}</Typography>
                      {i !== question.answers.length - 1 ? <Divider variant="fullWidth" className={classes.divider} /> : null}
                    </div>
                  )}
                  <Divider variant="fullWidth" className={classes.dividerPrimary} />
                </> 
              ) : null}
            </div>
          )}
          <Typography className={classes.questionTitle}>Postoji li još nešto što želiš podijeliti u ovom trenutku?</Typography>
          <Typography variant="caption">{ReactHtmlParser(lastQuestion && lastQuestion.notes)}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default ReportDetails;
