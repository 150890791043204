import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Avatar, Typography, CircularProgress, Divider, Fab } from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';
import firebase from '../../config/firebase';
import ActionMenu from '../ActionMenu/ActionMenu';
import ModalAdd from '../Modals/ModalAdd';
import ModalEdit from '../Modals/ModalEdit'
import ModalDelete from '../Modals/ModalDelete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '40ch',
      backgroundColor: theme.palette.background.paper,
      margin: '0 auto',
      marginTop: theme.spacing(1),
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    circularProgress: {
      textAlign: 'center',
      marginTop: '25%',
    },
  }),
);

const Users = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [addModal, setAddModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [users, setUsers] = useState<any>([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('users')
      .orderBy('username', 'asc')
      .onSnapshot(snapshot => {
        const users: any = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as any;
        setUsers(users);
        setLoading(false);
      });
    return () => unsubscribe();
  }, []);

  const toggleAddModal = () => {
    setAddModal(!addModal);
  };

  const toggleEditModal = (id?: string) => {
    const match = users.find(user => user.id === id);
    match ? setUser(match) : setUser(null);
    setEditModal(!editModal);
  };

  const toggleDeleteModal = (id: string) => {
    const match = users.find(user => user.id === id);
    match ? setUser(match) : setUser(null);
    setDeleteModal(!deleteModal);
  };

  if (loading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <List className={classes.root}>
      <Typography variant="h6" className={classes.marginLeft}>
        All users
      </Typography>
      {users.map((user, index) =>
        <div key={index}>
          <ListItem key={user.id}>
            <ListItemAvatar>
              <Avatar
                alt="user"
                src={process.env.PUBLIC_URL + `/images/${user.username}.jpg`}
              />
            </ListItemAvatar>
            <ListItemText
              primary={user.username}
              secondary={user.email}
            />
            <ListItemSecondaryAction>
              <ActionMenu id={user.id} onEditOpen={toggleEditModal} onDeleteOpen={toggleDeleteModal} />
            </ListItemSecondaryAction>
          </ListItem>
          {index !== users.length - 1 ? <Divider variant="inset" component="li" /> : null}
        </div>
      )}
      <Fab 
        color="primary"
        onClick={toggleAddModal}
        variant="extended"
        className={classes.marginLeft}
      >
        <PersonAdd />
        &nbsp; Add User
      </Fab>
      <ModalAdd open={addModal} onClose={toggleAddModal} />
      <ModalEdit open={editModal} onClose={toggleEditModal} user={user} />
      <ModalDelete open={deleteModal} onClose={toggleDeleteModal} user={user} />
    </List>
  );
}

export default Users;
