import { SET_USER } from '../actions';

const initialState = {
  currentUser: {
    isAuth: false,
    isAdmin: false,
    username: '',
  },
};

function setUser(state, action) {
  return {
    ...state,
    currentUser: {
      isAuth: Boolean(action.payload),
      isAdmin: Boolean(action.payload.role === 'admin'),
      ...action.payload
    },
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return setUser(state, action);

    default:
      return state;
  }
}
