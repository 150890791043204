import React, { ChangeEvent, useState } from 'react';
import { Select, MenuItem, Typography, FormControl } from '@material-ui/core';

const Dropdown = ({ items, label, item, onChange }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string);
  };

  const toggleSelect = () => {
    setOpen(!open);
  };

  return (
    <FormControl fullWidth>
      <Typography variant="subtitle1" style={{ marginTop: 20 }}>
        {label}
      </Typography>
      <Select
        open={open}
        onClose={toggleSelect}
        onOpen={toggleSelect}
        value={item}
        onChange={handleChange}
        required
      >
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Dropdown;
