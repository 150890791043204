import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, Button, Typography, Fab, Grid } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import FormReport from '../Forms/FormReport';
import FormSubmit from '../Forms/FormSubmit';
import { useSelector, useDispatch } from 'react-redux';
import { resetQuestions } from '../../redux/actions';
import firebase from '../../config/firebase';
import { dateToUnix } from '../../utils/datetime';
import { postOnSlack } from '../../utils/slack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }),
);

function getSteps() {
  return ['Login', 'Report project status', 'Submit report'];
}

function getStepContent(stepIndex: number) {
  switch (stepIndex) {
    case 0:
      return null;
    case 1:
      return <FormReport />;
    case 2:
      return <FormSubmit />;
    default:
      return 'Unknown stepIndex';
  }
}

export default () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);
  const { questions } = useSelector(state => state.questions);
  const { currentUser } = useSelector(state => state.users);
  const dispatch = useDispatch();
  const steps = getSteps();

  const handleSubmit = () => {
    firebase.firestore().collection('reports').add({
      date: dateToUnix(),
      currentUser,
      questions,
    });
    dispatch(resetQuestions({}));
    postOnSlack(currentUser.username);
    handleNext();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(1);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ padding: 20 }}>
        {activeStep === steps.length  ? (
          <Grid 
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.instructions}>Thanks for submiting your report!</Typography>
            <Fab color="primary" onClick={handleReset} variant="extended">
              New report
            </Fab>
          </Grid>
        ) : (
          <div className={classes.flexContainer}>
            {getStepContent(activeStep)}
            <div style={{ marginTop: 20 }}>
              <Button
                disabled={activeStep <= 1}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  startIcon={<Save />}
                >
                  Submit
                </Button>
              ): (
                <Button variant="contained" color="secondary" onClick={handleNext}>
                  Next
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
