import firebase from 'firebase/app';
import 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBbz9HDI8dKxZ8d75NLMwkNzc8eYiTtFR4",
  authDomain: "profico-reporting-tool.firebaseapp.com",
  databaseURL: "https://profico-reporting-tool.firebaseio.com",
  projectId: "profico-reporting-tool",
  storageBucket: "profico-reporting-tool.appspot.com",
  messagingSenderId: "909109318892",
  appId: "1:909109318892:web:941591f6e7df2d6c9341c9"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;