import React, { ChangeEvent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel } from '@material-ui/core';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector, useDispatch } from 'react-redux';
import { updateQuestion } from '../../redux/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      maxWidth: '650px',
    },
    formLabel: {
      marginBottom: theme.spacing(1),
    },
  }),
);

export default () => {
  const classes = useStyles();
  const { currentUser } = useSelector((state) => state.users);
  const { questions } = useSelector(state => state.questions);
  const question = questions[questions.length - 1];
  const dispatch = useDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>, editor: any) => {
    const value = editor.getData();
    dispatch(updateQuestion({ index: questions.length - 1, value, key: 'notes' }));
  };

  return (
    <FormControl className={classes.formControl}>
      <FormLabel className={classes.formLabel}>
        {`${currentUser.username}, postoji li još nešto što želiš podijeliti u ovom trenutku?`}
      </FormLabel>      
      <CKEditor
        editor={ClassicEditor}
        data={question.notes}
        onChange={handleChange}
      />
    </FormControl>
  );
}
