import React, { useState } from "react";
import clsx from "clsx";
import moment from 'moment'
import MomentUtils from '@date-io/moment';
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { isSameDay, isValid, format, isWithinInterval } from "date-fns";
// this guy required only on the docs site to work with dynamic date library
import { makeJSDateObject, dateToWeek, startOfWeek, endOfWeek } from "../../utils/datetime";
import { IconButton } from "@material-ui/core";

moment.updateLocale('en', { week: { dow: 1 } });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    dayWrapper: {
      position: "relative",
    },
    day: {
      width: 36,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: "0 2px",
      color: "inherit",
    },
    customDayHighlight: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "2px",
      right: "2px",
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: "50%",
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
      color: "#676767",
    },
    highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    firstHighlight: {
      extend: "highlight",
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    },
    endHighlight: {
      extend: "highlight",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  }),
);

const WeekPicker = ({ onWeekPick }) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleWeekChange = date => {
    setSelectedDate(startOfWeek(makeJSDateObject(date)));
    const week = dateToWeek(date);
    onWeekPick(week);
  };

  const formatWeekSelectLabel = (date, invalidLabel) => {
    let dateClone = makeJSDateObject(date);

    return dateClone && isValid(dateClone)
      ? `${format(startOfWeek(dateClone), "MMM do")} - ${format(endOfWeek(dateClone), "MMM do")}`
      : invalidLabel;
  };

  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    let dateClone = makeJSDateObject(date);
    let selectedDateClone = makeJSDateObject(selectedDate);

    const start = startOfWeek(selectedDateClone);
    const end = endOfWeek(selectedDateClone);

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        className={classes.marginLeft}
        value={selectedDate}
        onChange={handleWeekChange}
        renderDay={renderWrappedWeekDay}
        labelFunc={formatWeekSelectLabel}
      />
    </MuiPickersUtilsProvider>
  );
}

export default WeekPicker;