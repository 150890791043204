import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Avatar, Typography, CircularProgress, Divider } from '@material-ui/core';
import { BusinessCenter } from '@material-ui/icons';
import firebase from '../../config/firebase';
// import ActionMenu from '../ActionMenu/ActionMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '40ch',
      backgroundColor: theme.palette.background.paper,
      margin: '0 auto',
      marginTop: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(2),
    },
    circularProgress: {
      textAlign: 'center',
      marginTop: '25%',
    },
  }),
);

const Projects = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<any>([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('projects')
      .orderBy('name', 'asc')
      .onSnapshot(snapshot => {
        const projects: any = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as any;
        setProjects(projects);
        setLoading(false);
      });
    return () => unsubscribe();  
  }, []);

  if(loading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <List className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        All projects
      </Typography>
      {projects.map((project, index) =>
        <div key={index}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <BusinessCenter />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={project.name}
              secondary={project.description}
            />
            <ListItemSecondaryAction>
              {/* <ActionMenu /> */}
            </ListItemSecondaryAction>
          </ListItem>
          {index !== projects.length - 1 ?  <Divider variant="inset" component="li" /> : null}
        </div> 
      )}
    </List>
  );
}

export default Projects;
