import React from 'react';
import { Button } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';
import Answer from './QuestionAnswer';
import { useSelector, useDispatch } from 'react-redux';
import { updateQuestion } from '../../redux/actions';

interface IQuestionDetailsProps {
  index: number;
  detailsText: string;
}

export default ({ index, detailsText }: IQuestionDetailsProps) => {
  const question = useSelector(state => state.questions.questions[index]);
  const dispatch = useDispatch();

  const deleteAnswer = i => {
    const value = [
      ...question.answers.slice(0, i),
      ...question.answers.slice(i + 1)
    ];
    dispatch(updateQuestion({ index, value, key: 'answers' }));
  }

  const addAnswer = () => {
    const value = question.answers.concat([{ project: '', notes: '' }]);
    dispatch(updateQuestion({ index, value, key: 'answers' }));
  }

  return (
    <>
      {question.answers.map((answer, i) => (
        <Answer 
          key={i}
          index={index}
          answerIndex={i}
          answer={answer}
          detailsText={detailsText}
          onDelete={deleteAnswer}
        />
      ))}
      <div>
        <Button
          onClick={addAnswer}
          variant="contained"
          color="primary"
          startIcon={<PostAdd />}
        >
          Add More
        </Button>
      </div>
    </>
  );
};
