import { UPDATE_QUESTION, RESET_QUESTIONS } from '../actions';

export const initialState = {
  questions: [
    {
      value: false,
    },
    {
      value: false,
      answers: [
        {
          project: '',
          notes: '',
        },
      ],
    },
    {
      value: false,
      answers: [
        {
          project: '',
          notes: '',
        },
      ],
    },
    {
      value: false,
      answers: [
        {
          project: '',
          notes: '',
        },
      ],
    },
    {
      value: false,
      answers: [
        {
          project: '',
          notes: '',
        },
      ],
    },
    {
      notes: '',
    },
  ],
};

function updateQuestion(state, action) {
  return {
    ...state,
    questions: [
      ...state.questions.slice(0, action.payload.index),
      {
        ...state.questions[action.payload.index],
        [action.payload.key]: action.payload.value
      },
      ...state.questions.slice(action.payload.index + 1)
    ],
  };
}

function resetQuestions() {
  return {
    ...initialState,
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_QUESTION:
      return updateQuestion(state, action);

    case RESET_QUESTIONS:
      return resetQuestions();

    default:
      return state;
  }
}
