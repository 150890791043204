import { createMuiTheme } from '@material-ui/core/styles';
const theme  = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#f36c00',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      main: '#2fa81e',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: will be calculated to contrast with palette.secondary.main
    },
    text: {
      secondary: '#404040',
    },
  },
  typography: {
    fontFamily: [
      'Nunito',
      'sans-serif'
    ].join(','),
  },  
});

export default theme;