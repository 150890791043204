import React, { useState, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import PrivateNavLink from '../Router/PrivateNavLink';
import { useSelector } from 'react-redux';
import firebase from '../../config/firebase';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Menu, MenuItem, IconButton, Divider } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { QuestionAnswer,  ListAlt, PeopleAlt, ExitToApp } from '@material-ui/icons';
import logo from '../../assets/logo/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    fontWeight: 700,
  },
  logo: {
    height: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: '#404040',
    '&:hover': {
      opacity: 0.5,
    },
  },
  menuIcon: {
    marginRight: theme.spacing(1),
  }
}));

const Header = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { currentUser } = useSelector((state) => state.users);
  const open = Boolean(anchorEl);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    firebase.auth().signOut();
    window.location.reload();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <NavLink exact to="/">
          <img src={logo} className={classes.logo} alt="logo" />
        </NavLink>
        <Typography variant="h6" className={classes.title}>
          Operatio Reporticit
        </Typography>
        {currentUser.isAuth ? (
          <div>
            <IconButton onClick={handleMenu} color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <NavLink exact to="/" className={classes.link}>
                <MenuItem onClick={handleClose}>
                  <QuestionAnswer className={classes.menuIcon} />
                  Entry
                </MenuItem>
              </NavLink>
              {currentUser.isAdmin ? <Divider /> : null }
              <PrivateNavLink exact to="/reports" className={classes.link}>
                <MenuItem onClick={handleClose}>
                  <ListAlt className={classes.menuIcon} />
                  Reports
                </MenuItem>
              </PrivateNavLink>
              {/* <PrivateNavLink exact to="/projects" className={classes.link}>
                <MenuItem onClick={handleClose}>
                  <BusinessCenter className={classes.menuIcon} />
                  Projects
                </MenuItem>
              </PrivateNavLink> */}
              <PrivateNavLink exact to="/users" className={classes.link}>
                <MenuItem onClick={handleClose}>
                  <PeopleAlt className={classes.menuIcon} />
                  Users
                </MenuItem>
              </PrivateNavLink>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ExitToApp className={classes.menuIcon} />
                Logout
              </MenuItem>
            </Menu>
          </div>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
