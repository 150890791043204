import React, { ChangeEvent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, FormLabel, FormHelperText, Radio, RadioGroup, Divider } from '@material-ui/core';
import QuestionDetails from './QuestionDetails';
import { useSelector, useDispatch } from 'react-redux';
import { updateQuestion } from '../../redux/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    radioGroup: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    center: {
      textAlign: 'center',
    }
  }),
);

interface IQuestionProps {
  index: number;
  label: string;
  helperText: string;
  detailsText: string;
}

const Question = ({ index, label, helperText, detailsText }: IQuestionProps) => {
  const classes = useStyles();
  let { questions } = useSelector(state => state.questions);
  questions = questions.slice(1, questions.length - 1); // we don't need first and last questions
  const question = questions[index];
  const dispatch = useDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Boolean((event.target as HTMLInputElement).value === 'true');
    dispatch(updateQuestion({ index: index + 1, value, key: 'value' }));
  };

  return (
    <FormControl fullWidth>
      <Divider className={classes.divider} />
      <FormLabel className={classes.center}>{label}</FormLabel>
      <FormHelperText className={classes.center}>{helperText}</FormHelperText>
      <RadioGroup className={classes.radioGroup} value={question.value} onChange={handleChange}>
        <FormControlLabel value={true} control={<Radio />} label="Da" />
        <FormControlLabel value={false} control={<Radio />} label="Ne" />
      </RadioGroup>
      {question.value ? (
        <QuestionDetails index={index + 1} detailsText={detailsText} />
      ) : null}
    </FormControl>
  );
}

export default Question;
