import React, { ChangeEvent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import Question from '../Questions/Question';
import { useSelector, useDispatch } from 'react-redux';
import { updateQuestion } from '../../redux/actions';
import { QUESTIONS } from '../../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formLabel: {
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    radioGroup: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }),
);

const FormReport = () => {
  const classes = useStyles();
  const question = useSelector(state => state.questions.questions[0]);
  const { currentUser } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Boolean((event.target as HTMLInputElement).value === 'true');
    dispatch(updateQuestion({ index: 0, value, key: 'value' }));
  };

  return (
    <FormControl>
      <FormLabel className={classes.formLabel}>
        {`${currentUser.username}, postoje li operativni problemi ili drugi potencijalni izazovi na projektima koje vodiš?`}
      </FormLabel>
      <RadioGroup className={classes.radioGroup} value={question.value} onChange={handleChange}>
        <FormControlLabel value={true} control={<Radio />} label="Da" />
        <FormControlLabel value={false} control={<Radio />} label="Ne" />
      </RadioGroup>
      {question.value ? (
        <>
          {QUESTIONS.map((question, index) => (
            <Question
              key={index}
              index={index}
              label={question.label}
              helperText={question.helperText}
              detailsText={question.detailsText}
            />
          ))}
        </>
      ) : null}
    </FormControl>
  );
}

export default FormReport;
