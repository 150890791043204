import axios from 'axios';
import { WEBHOOKS } from '../config';

export const postOnSlack = (username: string) => {
  WEBHOOKS.forEach(webhook => {
    const url = `https://hooks.slack.com/services/${webhook.url}`;
    const data = {
      text: `Hi ${webhook.name}! :wave: You have a new report from *${username}*! :tada:\nhttps://op-reporting.profi.co/reports`,
    };
  
    axios.post(url, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [(data, headers) => {
        delete headers.post["Content-Type"]
        return data
      }]
    });
  })
}
