import moment from 'moment'

const dateToWeek = (s) => {
  const m = moment(s);
  return m.week();
}

const dateToUnix = () => {
  const m = moment();
  return m.unix();
}

const unixToDate = (s) => {
  const m = moment.unix(s);
  return m.format('HH:mm - DD/MM/YYYY');
}

const unixToWeek = (s) => {
  const m = moment.unix(s);
  return m.week();
}

const makeJSDateObject = (date) => {
  if (moment.isMoment(date)) {
    return date.clone().toDate();
  }

  if (date instanceof Date) {
    return new Date(date.getTime());
  }

  return date;
}

const startOfWeek = (s) => {
  const m = moment(s) ;
  return m.startOf('week').toDate();
}

const endOfWeek = (s) => {
  const m = moment(s) ;
  return m.endOf('week').toDate();
}

export {
  dateToWeek,
  dateToUnix,
  unixToDate,
  unixToWeek,
  makeJSDateObject,
  startOfWeek,
  endOfWeek,
}
