import React, { useState, ChangeEvent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormControl, TextField, FormLabel, InputLabel, MenuItem, Select, IconButton } from '@material-ui/core';
import { DeleteOutline, } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { updateQuestion } from '../../redux/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formLabel: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    buttonGroup: {
      textAlign: "right",
    }
  }),
);

interface IAnswer {
  project: string;
  notes: string;
}

interface IQuestionAnswer {
  index: number;
  answerIndex: number;
  answer: IAnswer;
  detailsText: string;
  onDelete: any;
}

const QuestionAnswer = ({ index, answerIndex, answer, detailsText, onDelete }: IQuestionAnswer) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const question = useSelector(state => state.questions.questions[index]);
  const { currentUser } = useSelector(state => state.users);
  const dispatch = useDispatch();

  const selectProject = (event: ChangeEvent<{ value: unknown }>) => {
    const value = [
      ...question.answers.slice(0, answerIndex),
      {
        ...question.answers[answerIndex],
        project: event.target.value as string,
      },
      ...question.answers.slice(answerIndex + 1)
    ];
    dispatch(updateQuestion({ index, value, key: 'answers' }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = [
      ...question.answers.slice(0, answerIndex),
      {
        ...question.answers[answerIndex],
        notes: event.target.value as string,
      },
      ...question.answers.slice(answerIndex + 1)
    ];
    dispatch(updateQuestion({ index, value, key: 'answers' }));
  };

  const handleDelete = (answerIndex) => {
    onDelete(answerIndex);
  }

  const toggleSelect = () => {
    setOpen(!open);
  }

  return (
    <FormControl key={index}>
      <InputLabel>Odaberi projekt...</InputLabel>
      <Select
        open={open}
        onClose={toggleSelect}
        onOpen={toggleSelect}
        value={answer.project}
        onChange={selectProject}
        required
      >
        {currentUser.projects.map((project) => (
          <MenuItem key={project} value={project}>
            {project}
          </MenuItem>
        ))}
      </Select>
      <FormLabel className={classes.formLabel}>{detailsText}</FormLabel>
      <TextField
        multiline
        rows={4}
        fullWidth={true}
        variant="outlined"
        value={answer.notes}
        onChange={handleChange}
      />
      <div className={classes.buttonGroup}>
        <IconButton onClick={() => handleDelete(answerIndex)} edge="end" color="inherit">
          <DeleteOutline color="action" />
        </IconButton>
      </div>
    </FormControl>
  )
}

export default QuestionAnswer;
