import React, { useState, useEffect } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Divider, CircularProgress, Switch, FormGroup, FormControlLabel, Select, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import firebase from '../../config/firebase';
import { unixToDate, unixToWeek } from '../../utils/datetime';
import WeekPicker from '../WeekPicker/WeekPicker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '95ch',
      backgroundColor: theme.palette.background.paper,
      margin: '0 auto',
      marginTop: theme.spacing(1),
    },
    formGroup: {
      justifyContent: 'space-between',
      borderRadius: '27px',
      padding: '0.5rem 1rem',
      margin: '1rem',
      border: '1px solid #f36c00',
      boxShadow: '0 4px 16px 0 rgba(0,0,0,0.07), 0 31px 44px 0 rgba(0,0,0,0.03)',
    },
    formControlLabel: {
      marginRight: theme.spacing(2),
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    wordBreak: {
      wordBreak: 'break-word',
    },
    inline: {
      display: 'inline',
    },
    link: {
      textDecoration: 'none',
      color: '#404040',
    },
    listItem: {
      '&:hover': {
        color: '#fff',
        backgroundColor: theme.palette.secondary.main,
      }
    },
    circularProgress: {
      textAlign: 'center',
      marginTop: '25%',
    },
  }),
);

const Report = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [reports, setReports] = useState<any>([]);
  const [user, setUser] = useState<string>('-');
  const [users, setUsers] = useState<string[]>(['-']);
  const [project, setProject] = useState<string>('-');
  const [projects, setProjects] = useState<string[]>(['-']);
  const [filteredReports, setFilteredReports] = useState<any>([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('reports')
      .orderBy('date', 'desc')
      .onSnapshot(snapshot => {
        const reports: any = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as any;
        setReports(reports);
        setFilteredReports(reports);
        setLoading(false);
      });
    return () => unsubscribe();  
  }, []);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('users')
      .orderBy('username', 'asc')
      .onSnapshot(snapshot => {
        const users: any = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as any;
        mapDataFromUsersCollection(users);
        setLoading(false);
      });
    return () => unsubscribe();
  }, []);


  const mapDataFromUsersCollection = users => {
    // projects
    let projects: any = [];
    users.forEach(user => {
      projects = [...projects, ...user.projects];
    });
    projects.sort(); // sort alphabetically 
    projects = projects.filter((item, index) => projects.indexOf(item) === index); // remove duplicate values
    setProjects(projects);

    // users 
    const usernames = users.map(user => user.username);
    setUsers(['-', ...usernames]);
  }

  const handleToggle = () => {
    setChecked(!checked);
    if (checked) {
      setFilteredReports(reports);
    } else {
      const results = reports.filter(report => report.questions[0].value);
      setFilteredReports(results);
    }
    // reset other filters:
    setUser('-');
    setProject('-');
  };

  const handleWeekPick = (week) => {
    const results = reports.filter(report => unixToWeek(report.date) === week);
    setFilteredReports(results);
    // reset other filters:
    setChecked(false);
    setUser('-');
    setProject('-');
  }

  const handleUserPick = (event) => {
    const selectedUser = event.target.value as string;
    setUser(selectedUser);
    const results = reports.filter(report => report.currentUser.username === selectedUser);
    selectedUser === '-' ? setFilteredReports(reports) : setFilteredReports(results);
    // reset other filters:
    setChecked(false);
    setProject('-');
  }

  const handleProjectPick = (event) => {
    const selectedProject = event.target.value as string;
    setProject(selectedProject);
    const results = reports.filter(report => report.currentUser.projects.includes(selectedProject));
    selectedProject === '-' ? setFilteredReports(reports) : setFilteredReports(results);
    // reset other filters:
    setChecked(false);
    setUser('-');
  }

  if(loading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <List className={classes.root}>
      <Typography variant="h6" className={classes.marginLeft}>Filter reports</Typography>
      <FormGroup className={classes.formGroup} row>
        <FormControlLabel
          className={classes.formControlLabel}
          control={<Switch color="primary" checked={checked} onChange={handleToggle} />}
          label="with problems:"
          labelPlacement="start"
        />
        <FormControlLabel
          className={classes.formControlLabel}
          control={<WeekPicker onWeekPick={handleWeekPick} />}
          label="by week:"
          labelPlacement="start"
        />
        <FormControlLabel
          className={classes.formControlLabel}
          control={
          <Select
            className={classes.marginLeft}
            value={user}
            onChange={handleUserPick}
          >
            {users.map((user, index) => (
              <MenuItem key={index} value={user}>
                {user}
              </MenuItem>
            ))}
          </Select>
          }
          label="by user:"
          labelPlacement="start"
        />
        <FormControlLabel
          className={classes.formControlLabel}
          control={
          <Select
            className={classes.marginLeft}
            value={project}
            onChange={handleProjectPick}
          >
            {projects.map((project, index) => (
              <MenuItem key={index} value={project}>
                {project}
              </MenuItem>
            ))}
          </Select>
          }
          label="by project:"
          labelPlacement="start"
        />
      </FormGroup>
      {filteredReports.map((report, index) => 
        <div key={index}>
          <NavLink to={`/reports/${report.id}`} className={classes.link}>
            <ListItem alignItems="flex-start" className={classes.listItem}>
              <ListItemAvatar>
                <Avatar 
                  alt="user"
                  src={process.env.PUBLIC_URL + `/images/${report.currentUser.username}.jpg`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={report.currentUser.username}
                className={classes.wordBreak}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {unixToDate(report.date)}
                    </Typography>
                    {ReactHtmlParser(` - ${report.questions[report.questions.length - 1].notes}`)}
                  </>
                }
              />
            </ListItem>
          </NavLink>
          {index !== reports.length - 1 ?  <Divider variant="inset" component="li" /> : null}
        </div>
      )}
    </List>
  );
}

export default Report;
