export const WEBHOOKS = [
  {
    channel: "mateoperak",
    name: "Mateo",
    url: "T04C87S5U/B013AQJGLSX/CM9bS5InraLuaqPIQeuQpFWm",
  },
  {
    channel: "ante",
    name: "Ante",
    url: "T04C87S5U/B013P7C4UQ1/6HvFq7zea69t6yGPYV4qIQzz",
  },
  {
    channel: "ferenc",
    name: "Ferenc",
    url: "T04C87S5U/B02HTSN2CQ5/acRJYvxtziPrjM7a0qoKGuin",
  },
  {
    channel: "miro",
    name: "Miro",
    url: "T04C87S5U/B02J9ES39Q9/NCAe7xEhdQisDawVrCEHkr4S",
  },
];
