import React, { ChangeEvent, useState } from 'react';
import ChipInput from 'material-ui-chip-input'
import { FormControl, Select, MenuItem, Button, Typography } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import firebase from '../../config/firebase';

const ModalEditForm = ({ user, onClose }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [role, setRole] = useState<string>(user.role);
  const [projects, setProjects] = useState<string[]>(user.projects);

  const selectRole = (event: ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as string);
  };

  const toggleSelect = () => {
    setOpen(!open);
  };

  const handleAddChip = (chip: string) => {
    setProjects([...projects, chip]);
  };

  const handleDeleteChip = (chip: string) => {
    setProjects(projects.filter(project => project !== chip));
  };

  const handleSave = () => {
    firebase.firestore().collection('users').doc(user.id).update({
      role, projects
    });
    onClose();
  };

  return (
    <FormControl fullWidth>
      <Typography variant="subtitle1" style={{ marginTop: 20 }}>
        Role:
      </Typography>
      <Select
        open={open}
        onClose={toggleSelect}
        onOpen={toggleSelect}
        value={role}
        onChange={selectRole}
        required
      >
        {['user', 'admin'].map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))}
      </Select>
      <Typography variant="subtitle1" style={{ marginTop: 20 }}>
        Projects:
      </Typography>
      <ChipInput
        value={projects}
        onAdd={(chip) => handleAddChip(chip)}
        onDelete={(chip) => handleDeleteChip(chip)}
      />
      <div style={{ marginTop: 20 }}>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          startIcon={<Save />}
        >
          Save
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{ marginLeft: 10 }}
        >
          Cancel
        </Button>
      </div>
    </FormControl>
  );
}

export default ModalEditForm;
