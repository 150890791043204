import React, { useState, useEffect } from 'react';
import ChipInput from 'material-ui-chip-input'
import { Button, Typography } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import Dropdown from './Dropdown';
import firebase from '../../config/firebase';

const ModalAddForm = ({ onClose }) => {
  const [employees, setEmployees] = useState<any[]>([]);
  const [user, setUser] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [projects, setProjects] = useState<string[]>([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('employees')
      .orderBy('username', 'asc')
      .onSnapshot(snapshot => {
        const employees: any[] = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employees);
      });
    return () => unsubscribe();
  }, []);

  const handleAddChip = (chip: string) => {
    setProjects([...projects, chip]);
  };

  const handleDeleteChip = (chip: string) => {
    setProjects(projects.filter(project => project !== chip));
  };

  const handleSave = () => {
    const { username, email } = employees.find(employee => employee.username === user);
    firebase.firestore().collection('users').add({
      username,
      email,
      role,
      projects,
    });
    onClose();
  };

  return (
    <>
      <Dropdown items={employees.map(e => e.username)} label="User:" item={user} onChange={setUser} />
      <Dropdown items={['user', 'admin']} label="Role:" item={role} onChange={setRole} />
      <Typography variant="subtitle1" style={{ marginTop: 20 }}>Projects:</Typography>
      <ChipInput
        value={projects}
        onAdd={(chip) => handleAddChip(chip)}
        onDelete={(chip) => handleDeleteChip(chip)}
      />
      <div style={{ marginTop: 20 }}>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          startIcon={<Save />}
        >
          Save
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{ marginLeft: 10 }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}

export default ModalAddForm;
